<template>
    <div>
        <section class="relative leading-7 text-gray-900 bg-white border-solid pb-8 pt-5">
            <div class="box-border mx-auto border-solid lg:pl-8 max-w-7xl">
                <div class="flex flex-col items-center leading-7 text-gray-900 border-0 border-gray-200 lg:flex-row">
                    <div
                        class="box-border flex flex-col justify-center w-full h-full p-8 text-gray-900 border-solid lg:w-1/2 md:p-16 lg:p-0 lg:pl-10 lg:pr-20">
                        <h2 class="m-0 text-3xl font-medium leading-tight tracking-tight text-left text-black sm:text-4xl md:text-4xl">
                            How to verify your token</h2>
                        <p class="mt-2 text-xl text-left border-0 border-gray-200 sm:text-xl">On <a
                            href="https://bscscan.com"> BSCScan</a></p>
                        <div
                            class="grid gap-4 mt-8 leading-7 border-0 border-gray-200 sm:mt-10 sm:gap-6 lg:mt-12 lg:gap-8">
                            <div class="box-border flex items-start text-gray-900 border-solid">
                                <div
                                    class="flex items-center justify-center w-12 h-12 leading-7 bg-blue-600 border-0 border-gray-200 rounded-full">
                                    <p class="box-border m-0 text-xl text-white border-solid">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                  d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"/>
                                        </svg>
                                    </p>
                                </div>
                                <div class="flex-1 ml-6 leading-7 border-0 border-gray-200">
                                    <h3 class="box-border m-0 text-lg font-semibold leading-tight tracking-tight text-black border-solid sm:text-xl md:text-2xl">
                                        Create an account</h3>
                                    <p class="box-border mt-2 text-base leading-normal text-gray-900 border-solid">You
                                        will need to create an account on <a href="https://bscscan.com">BSCScan</a> in
                                        order to verify your contract.</p>
                                </div>
                            </div>
                            <div class="box-border flex items-start text-gray-900 border-solid">
                                <div
                                    class="flex items-center justify-center w-12 h-12 leading-7 bg-blue-600 border-0 border-gray-200 rounded-full">
                                    <p class="box-border m-0 text-xl text-white border-solid">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                                                 viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                      d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"/>
                                            </svg>
                                    </p>
                                </div>
                                <div class="flex-1 ml-6 leading-7 border-0 border-gray-200">
                                    <h3 class="box-border m-0 text-lg font-semibold leading-tight tracking-tight text-black border-solid sm:text-xl md:text-2xl">
                                        Verify your contract address</h3>
                                    <p class="box-border mt-2 text-base leading-normal text-gray-900 border-solid">
                                        Verify your contract address in order to update token information, sales info,
                                        Dapp page, name tags and project label(s).
                                    </p>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div
                        class="w-full h-full overflow-hidden leading-7 text-gray-900 border-0 border-gray-200 lg:w-1/2 rounded-lg shadow-lg">
                        <img src="https://bscscan.com/images/brandassets/BscScan-logo.png"
                             class="object-cover w-full h-full">
                    </div>
                </div>
            </div>
        </section>

        <!-- Section 2 -->
        <middle-banner />

        <!-- Section 3 -->
        <section class="w-full py-12 bg-white sm:py-20">
            <div class="flex flex-col items-center justify-center mx-auto xl:flex-row max-w-7xl">
                <div
                    class="relative flex flex-col items-start justify-center w-full h-full max-w-2xl px-6 mx-auto xl:max-w-none xl:w-2/5">

                    <h2 class="text-3xl font-normal leading-none text-gray-900 sm:w-4/5 sm:text-6xl text-3">Sites that
                        we recommend</h2>
                    <div class="w-20 h-1 mt-3 ml-1 bg-indigo-600 rounded-full"></div>

                    <svg
                        class="absolute top-0 right-0 hidden w-auto h-24 mt-20 text-indigo-600 fill-current sm:block xl:mr-5 xl:-mt-24"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180 74">
                        <defs></defs>
                        <g fill-rule="nonzero">
                            <path
                                d="M43.13 71.58c.29-.025.58-.025.87 0 .3 0 .71-.14 1-.17.54 0 1.08-.19 1.62-.23l2.3-.17 4.65-.25 6.47-.26h.68c3-.15 6-.3 9-.53 1.68-.14 5.83-.49 6.21-.52.63 0 4.36-.53 5-.63.25 0 1.95-.35 3.35-.58-.09-1-.55-1.77-.62-2.71-4.33.66-8.7 1.18-13 1.51-5.34.4-10.71.66-16.08 1l-7 .39c-1.4 0-2.82-.31-4.17-.26a2 2 0 00-1 .31c-.3.21-.4.16-.5.3a1.18 1.18 0 00-.12.81c.06.84.66 2.11 1.34 1.99zM106.33 62.04l.5-.23c.31-.13.72-.33 1.16-.56.88-.46 1.94-1 2.59-1.38l.56-.31a31.87 31.87 0 002.81-1.74 48.77 48.77 0 0014-13.59 38.22 38.22 0 004.34-8.87 28.9 28.9 0 001.51-9.86 31.56 31.56 0 00-3.3-13.46 23.9 23.9 0 00-3.62-5.22 20.47 20.47 0 00-2.38-2.22c-.42-.34-.89-.63-1.33-.94a10.88 10.88 0 00-1.38-.85A18.21 18.21 0 00109.16.92c-.5.11-1 .22-1.45.33-.45.11-.88.31-1.3.47-.42.16-.86.3-1.27.49-.41.19-.8.41-1.21.61A27.71 27.71 0 0098.5 6.5l-1.51 1.42-1.59 1.84c-.51.66-1 1.36-1.44 2-.44.64-.75 1.28-1 1.78a22.66 22.66 0 00-2 5 23.44 23.44 0 00-.82 5.31 26.71 26.71 0 00.72 7c.22 1.16.65 2.26 1 3.38a33.94 33.94 0 001.41 3.21 36.93 36.93 0 008.44 10.95 47.5 47.5 0 005.77 4.43 35.5 35.5 0 0010.02 4.59 86.41 86.41 0 0010 2.09 84.59 84.59 0 0018.2.51c4.8-.31 9.33-.8 13.8-1.39 2.25-.3 4.49-.63 6.76-1l3.43-.59.83-.11a15.59 15.59 0 001.98-.25 46 46 0 014.66-.82c.69-.12 2.24-.87 2.34-1.35.06-.28-.19-.56-.15-.85.09-.65-1.16-1.47-2.06-1.25-.9.22-1.89.51-2.84.73-.95.22-2 .37-3 .62a9.82 9.82 0 00-1.16.38c-.19.06-.39.13-.58.18l-.29.08-.69.12c-3.55.62-7 1.3-10.81 1.68-5.54.53-11.42 1.31-17.15 1.37a73.61 73.61 0 01-18.84-1.81 41.54 41.54 0 01-16.81-8.06 37.32 37.32 0 01-7.9-8.78 27.1 27.1 0 01-4.12-10.8C91.25 17.6 98.76 6.5 108.89 3.76a15.83 15.83 0 0114.56 3.4 23.24 23.24 0 017.36 13.74 28.32 28.32 0 01.29 8 28.05 28.05 0 01-2.06 7.7 37.16 37.16 0 01-5 8.63 39.08 39.08 0 01-7 7l-.87.66-.14.11c-1.69 1.29-3.61 2.56-5.55 3.75a54.34 54.34 0 01-12 5.4c-.42 1-1 2.35-.6 3.17 1.18-.35 2.25-.69 3.52-1.19.8-.28 1.61-.63 2.44-1 .83-.37 1.66-.72 2.49-1.09z"></path>
                            <path
                                d="M46.93 71a8.72 8.72 0 011.16 0c.51 0 1.48.05 2 0l8.52-.5c8.84-.54 17.78-1 26.66-2.45 2.33-.38 4.67-.8 7-1.29a56.65 56.65 0 0010.45-3.26 85.2 85.2 0 009.11-4.57 48.44 48.44 0 0014-12c.86-1 1.57-2.14 2.33-3.2s1.34-2.12 1.89-3.23a35.91 35.91 0 002.81-7.11 31.08 31.08 0 00.4-12.78 6.21 6.21 0 01-1.89 2.64 25.44 25.44 0 01-1 9.32l-.09.26a21.31 21.31 0 01-.69 2 41.94 41.94 0 01-3.72 7.43 41.78 41.78 0 01-5.3 6.63 52.72 52.72 0 01-15.45 10.61 73.71 73.71 0 01-18.17 5.41 207.23 207.23 0 01-24.09 2.59l-15.92.87a4.07 4.07 0 01-.01 2.63zM21.1 71.79a1.43 1.43 0 01-.27-1.49 2.72 2.72 0 011.81-1.54c1-.14 2.13.44 3.2.44 1.47 0 2.94-.27 4.42-.39 1-.08 1.92 0 2.86-.15a17 17 0 012.57-.11 5.7 5.7 0 001.17 0 3 3 0 011.12-.16c1 .18 1.3 2.22.71 2.91-.45.53-1.56.36-2.18.36h-2.67c-2.13.13-4.28 0-6.41.1-.91 0-1.8.24-2.7.35-.9.11-1.7.15-2.56.2a1.31 1.31 0 01-1.07-.52zM7.5 71.7a3.09 3.09 0 010-1 1.26 1.26 0 01.4-.74 2.18 2.18 0 012.16-.49 9.2 9.2 0 002.87 0 9.22 9.22 0 013.1 0 2 2 0 011.17.72c.46.6.61 1.35-.14 1.8a5.18 5.18 0 01-2.91.44c-1.34-.13-2.75.53-4.15.76a2 2 0 01-1.34-.22A2.49 2.49 0 017.5 71.7zM.01 71.57c.082-.29.2-.569.35-.83a1.91 1.91 0 013.27-.25c.54.63.61 2.26-.16 2.72a4.27 4.27 0 01-1.32.44c-1.12.1-2.05-.23-2.14-2.08z"></path>
                        </g>
                    </svg>

                </div>
                <div
                    class="box-content relative flex items-center w-full h-auto max-w-2xl py-5 mx-auto overflow-hidden xl:w-3/5 rounded-xl">

                    <!-- Slide Page 1 -->
                    <div class="flex px-6 space-x-6 transition duration-500 ease-out transform xl:pl-6 h-94 relative">

                        <!-- story 1 -->
                        <div class="flex flex-col flex-shrink-0 w-1/2 overflow-hidden rounded-lg shadow-lg mx-auto">
                            <div class="flex-shrink-0">
                                <a href="https://bscscan.com">
                                    <img class="object-cover w-full h-32 sm:h-48"
                                         src="https://miro.medium.com/fit/c/1360/1360/1*RbK1YYzgQg6uni0TXU4Ghw.png"
                                         alt="">
                                </a>
                            </div>
                            <div class="flex flex-col justify-between flex-1 p-6 bg-white">
                                <div class="flex-1">
                                    <p class="inline-block py-1 pl-10 pr-4 mb-2 -ml-10 text-xs font-medium leading-5 text-white transform -translate-y-2 bg-indigo-600 rounded">
                                        <a href="https://bscscan.com" class="hover:underline" rel="category">Blockchain
                                            explorer</a>
                                    </p>
                                    <a href="https://bscscan.com" class="block">
                                        <span
                                            class="mt-2 text-base font-semibold leading-tight leading-7 text-gray-900 sm:text-xl">BSC Scan</span>
                                        <span class="block mt-3 text-xs leading-6 text-gray-500 sm:text-sm">BSC Scan is the biggest blockchain explorer for the Binance Smart Chain. It's the goto site for any tokens deployed. </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Section 4 -->
        <section class="py-20 bg-white">
            <div class="flex flex-col px-8 mx-auto space-y-12 max-w-7xl xl:px-12">
                <div class="relative">
                    <h2 class="w-full text-3xl font-bold text-center sm:text-4xl md:text-5xl">Verifying your
                        contract</h2>
                    <p class="w-full py-8 mx-auto -mt-2 text-lg text-center text-gray-700 intro sm:max-w-3xl"></p>
                </div>
                <div class="flex flex-col mb-8 animated fadeIn sm:flex-row">
                    <div class="flex items-center mb-8 sm:w-1/2 md:w-5/12 sm:order-last">
                        <img class="rounded-lg shadow-xl" src="https://i.imgur.com/rzbuRJx.png" alt="">
                    </div>
                    <div class="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
                        <p class="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">
                            Registration on BSCScan</p>
                        <h3 class="mt-2 text-2xl sm:text-left md:text-4xl">Registering on BSCScan</h3>
                        <p class="mt-5 text-lg text-gray-700 text md:text-left">To create your account visit <a
                            href="https://bscscan.com/register" class="underline">BSCScan</a> and sign up. Remember to
                            use a valid email, as you have to verify your email in order to finish registration. This
                            account will be used to verify that you are the owner of the contract address</p>
                    </div>
                </div>
                <div class="flex flex-col mb-8 animated fadeIn sm:flex-row">
                    <div class="flex items-center mb-8 sm:w-1/2 md:w-5/12">
                        <img class="rounded-lg shadow-xl" src="https://i.imgur.com/Q1CMwo4.png" alt="">
                    </div>
                    <div class="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pl-16">
                        <p class="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">
                            VERIFICATION</p>
                        <h3 class="mt-2 text-2xl sm:text-left md:text-4xl">Verifying your contract address</h3>
                        <p class="mt-5 text-lg text-gray-700 text md:text-left">To verify your contract address, head
                            over to <a href="https://bscscan.com/myverify_address" class="underline">this link</a>.
                            <br/>Now click the blue
                            <img class="inline-block" src="https://i.imgur.com/NzINBQK.png"> on top, next to "My
                            verified addresses". Enter your contract address in the box and hit next. You will now be
                            redirected to a new page where you will be asked to sign a message from BSCScan. Simply
                            connect your web3 wallet and complete the hashing. </p>
                    </div>
                </div>
                <div class="flex flex-col mb-8 animated fadeIn sm:flex-row">
                    <div class="flex items-center mb-8 sm:w-1/2 md:w-5/12 sm:order-last">
                        <img class="rounded-lg shadow-xl" src="https://i.imgur.com/4vkg2UB.png" alt="">
                    </div>
                    <div class="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
                        <p class="mb-2 text-sm font-semibold leading-none text-left text-indigo-600 uppercase">HASHED
                            MESSAGE</p>
                        <h3 class="mt-2 text-2xl sm:text-left md:text-4xl">Verifying your hashed message</h3>
                        <p class="mt-5 text-lg text-gray-700 text md:text-left">Once you've completed hashing your
                            message, you'll see a new screen consisting of: Contract/owner address, Signed message and
                            the hashed signature of the message. Click the <img src="https://i.imgur.com/QVJZKTg.png"
                                                                                class="inline-block"> and your
                            verification should be done. Tokengen does not provide any verification support. If anything
                            goes wrong, you must consult with BSCScan.</p>
                    </div>
                </div>

            </div>
        </section>
    </div>
</template>

<script>
import MiddleBanner from './MiddleBanner';
export default {
    name: 'VerifyOnBscScan',
    components: { MiddleBanner },
};
</script>

<style scoped>

</style>
